.container {
    width: 500px;
    padding: 5% 0 0;
    margin: auto;
    /*background-color: #f4f4f4;*/
}


.layout {
    margin-top: 32px !important;
    margin: 0;
    padding: 0;
}

.login {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border: solid 1px #e8eced;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.login-form {
    /*padding: 32px;*/
    margin: 0;
    height: 100%;
}

.login-tab-container {
    background-color: #f4f4f4;
    height: 85px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    text-align: center;
    width: 100%;
}

    .login-tab-container > .login-tab-button {
        flex-grow: 1;
        opacity: 0.5;
        font-family: Arial;
        font-size: 16px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.11;
        letter-spacing: 0.3px;
        text-align: center;
        color: #2d2d2d;
        border-bottom: none;
        text-align: center;
        position: relative;
        background-color: #ffffff;
        border: solid 1px #e8eced;
    }

        .login-tab-container > .login-tab-button:disabled {
            opacity: 1;
            cursor: default;
            color: #3d3f3f;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            border: solid 1px #e8eced;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
        }

    .login-tab-container > .left {
        margin-right: 2px;
    }

    .login-tab-container > .right {
        margin-left: 2px;
    }

    .login-tab-container > .login-tab-button:disabled::before {
        content: '';
        height: 4px;
        width: 100%;
        background-color: #147cbd;
        position: absolute;
        top: -4px;
        left: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

    .login-tab-container > .login-tab-button:disabled::after {
        content: '';
        height: 8px;
        width: 100%;
        background-color: #ffffff;
        position: absolute;
        bottom: -4px;
        left: 0;
        box-sizing: border-box;
    }

/*need to merge with the library css for input*/

.login input,
input[type=email],
input[type=password] {
    width: 100% !important;
    height: 40px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border-radius: 2px !important;
    background-color: #ffffff !important;
    border: solid 1px #898d8d;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: 0.2px;
}

.login ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.login .tab-content li {
    padding-bottom: 20px;
}

    .login .tab-content li:nth-child(2) {
        padding-bottom: 32px;
    }

    .login .tab-content li:nth-child(3) {
        display: none;
    }

    .login .tab-content li:last-child {
        padding: 0;
    }

.login button[type=submit] {
    height: 53.9px;
    width: 100%;
}

a.center {
    text-align: center !important;
    display: block !important;
}

.login .error {
    font-size: 14.4px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.11;
    letter-spacing: 0.2px;
    text-align: center !important;
    margin-bottom: 32px;
}

li.error {
    margin-top: -12px;
}

/* need to move in dm library */

.content {
    border-radius: 5px;
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    border: solid 1px #e8eced;
    margin: 0;
    padding: 32px;
    position: relative;
}

    .content::before {
        content: '';
        height: 4px;
        width: 100%;
        background-color: #147cbd;
        position: absolute;
        top: -4px;
        left: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
    }

.content-header {
    font-family: Arial;
    font-size: 18.8px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.17;
    letter-spacing: 0.3px;
    text-align: left;
    color: #3d3f3f;
    padding-bottom: 32px;
}


.content input {
    width: 100% !important;
    height: 40px !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    border-radius: 2px !important;
    background-color: #ffffff !important;
    border: solid 1px #898d8d;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.content-rules {
    font-size: 14.4px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.32;
    letter-spacing: 0.2px;
    text-align: left;
    color: #3d3f3f;
}

.content ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}


.content li {
    padding-bottom: 32px;
}

    .content li.small {
        padding-bottom: 20px;
    }

    .content li:last-child {
        padding: 0;
    }

button[type=submit] {
    height: 53.9px;
    width: 100%;
}

.dm-ui-modal {
    position: fixed !important;
}


.scroll-outer {
    padding-right: 8px;
    padding-top: 8px;
    /* height: 410px; */
    border-radius: 2px;
    border: solid 1px #bfc0c0;
}

.scroll-inner {
    padding-top: 24px;
    padding-right: 24px;
    padding-bottom: 10px;
    padding-left: 32px;
    overflow-y: scroll;
    height: 450px;
}

/*.overview-messages > h3,
.overview-messages > ul,
.overview-messages > ol {
    padding-top: 20px !important;
}

.overview-messages ul {
    list-style-type: disc;
    margin-left: 16px;
}

    .overview-messages ul li {
        padding-bottom: 0 !important;
    }*/


.left-option {
    width: 50%;
    padding-right: 40px;
}

.right-option {
    width: 50%;
}

.option-height {
    height: 84px;
}

.large-modal {
    width: 600px !important;
}

.tab-content-section {
    padding-bottom: 20px;
}

.tab-content-section-large {
    padding-bottom: 32px;
}

.tos h2 {
    text-align: center;
}
.tos h2 {
    margin-bottom: 24px;
}
.tos ol {
    margin: 16px 0 0 0;
}
    .tos ol li {
        margin-left: 70px;
        padding: 10px 15px 10px 13px !important;
        list-style-position: outside;
    }
.tos ul {
    list-style-type: disc;
}
.tos .copyright {
    margin: 10px 0 20px 0;
    font-size: 13px;
    line-height: 13px;
}

.login-release-message {
    margin: 0 auto;
    font-size: 15px;
    color: #BA0E10;
    font-weight: normal;
    overflow: visible;
    margin-top: 24px;
}